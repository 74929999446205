<template>
  <div>
    <Spinner v-if="loading.initial">Loading preview...</Spinner>
    <div v-else class="d-flex justify-content-between align-items-center px-4">
      <ul class="pagination">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <button class="page-link" @click.prevent="currentPage -= 1">
            <i class="fa fa-angle-left"></i>
          </button>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === pdfDocument.numPages }">
          <button class="page-link" @click.prevent="currentPage += 1">
            <i class="fa fa-angle-right"></i>
          </button>
        </li>
        <li class="page-item ml-3">
          <button class="page-link" @click="popup">
            <i v-if="!loading.popup" class="fas fa-external-link-alt"></i>
            <i v-else class="fa fa-spin fa-circle-notch" />
          </button>
        </li>
        <li class="page-item ml-3">
          <button class="page-link" @click="download">
            <i v-if="!loading.download" class="fas fa-download"></i>
            <i v-else class="fa fa-spin fa-circle-notch" />
          </button>
        </li>
      </ul>
      <div class="font-w700">Page {{ currentPage }} of {{ pdfDocument.numPages }}</div>
    </div>
    <div class="pdf-preview mb-4">
      <PdfViewer :src="loading.pdf" :page="currentPage" />
    </div>
  </div>
</template>
<script>
import PdfViewer from 'vue-pdf';
import { mapActions, mapGetters } from 'vuex';
import { friendlyInvoiceName } from '../lib/helpers.js';
import slugify from 'slugify';

import Spinner from './Spinner';

export default {
  name: 'InvoiceValidationPreview',
  components: {
    PdfViewer,
    Spinner
  },
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentPage: 1,
      pdfDocument: null,
      browserUrl: null,
      loading: {
        initial: true,
        pdf: null,
        popup: false,
        download: false
      }
    };
  },
  computed: {
    ...mapGetters({
      loadingAction: 'invoice/loadingAction'
    })
  },
  async mounted() {
    const { url, browserUrl } = await this.downloadInvoice({ id: this.invoice._id, name: 'preview' });

    this.loading.pdf = PdfViewer.createLoadingTask(url);

    this.pdfDocument = await this.loading.pdf.promise;
    this.browserUrl = browserUrl;

    this.loading.initial = false;
  },
  beforeDestroy() {
    if (this.loading.pdf) {
      this.loading.pdf.destroy();
    }
  },
  methods: {
    ...mapActions({
      downloadInvoice: 'invoice/download'
    }),
    async popup() {
      this.loading.popup = true;
      const { browserUrl } = await this.downloadInvoice({ id: this.invoice._id, name: 'preview' });

      window.open(
        browserUrl,
        'popUpWindow',
        'height=1000,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
      );
      this.$emit('popup');
      this.loading.popup = false;
    },
    async download() {
      this.loading.download = true;

      const rawFileName = friendlyInvoiceName(this.invoice, true);
      const fileName = slugify(rawFileName, {
        replacement: ' ',
        lower: false
      });
      const { url } = await this.downloadInvoice({ id: this.invoice._id, name: fileName });

      const link = document.createElement('a');
      link.target = '_blank';
      link.href = url;
      link.download = fileName;
      link.click();

      this.loading.download = false;
    }
  }
};
</script>
